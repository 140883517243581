@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');

/*@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600;700&display=swap');*/

* {
  box-sizing: border-box;
  padding:0;
  margin:0
}

html,
body {
  /*font-family: 'Sora', sans-serif;*/
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111517;
  color:#fff;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  vertical-align: middle;
}


.web3modal-modal-lightbox { z-index: 1000 !important; }