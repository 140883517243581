.layout {
  @apply 
  flex flex-col
  min-h-screen
  mx-auto
  overflow-auto lg:overflow-hidden
  overflow-x-hidden
  px-8 xl:px-0
  content-center
}

.main {
  @apply 
  flex-1
  text-white z-30
  items-center
  content-center
  justify-center
 
}

.header {
  @apply 
  h-24
  z-40
}

.footer {
  @apply
  h-16
  z-20
}

.bgImageFirst {
  @apply
  fixed top-28 -left-60 z-10
}

.bgImageSecond {
  @apply
  fixed bottom-16 right-4 z-10
}