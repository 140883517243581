.header {
  @apply flex justify-between 
  items-center md:items-center
  flex-row md:flex-row
  
  pt-4 md:pt-0
  md:px-8;
}

.rightSide {
  @apply xl:flex items-center gap-8 md:gap-16
  mt-4 md:mt-0  hidden;
}

.pagesContainer {
  @apply flex items-center gap-4 md:gap-10;
}
.pagesContainerMobile {
  @apply flex flex-col items-center gap-4 
  pt-10;
}

.activeLink {
  @apply text-blue-400 font-semibold;
}

.connectWalletButton {
  @apply border rounded-full
  px-4 py-1;
}

.dropdown {
  @apply relative inline-block;
}
.dropdownMobile {
  @apply relative inline-block;
}

.dropdownContent {
  @apply absolute right-0
  z-30 
  hidden
  bg-main-dark text-white border border-white
  rounded-lg
  w-48;
}
.dropdownContentMobile {
  @apply relative right-0
  z-30 
  hidden
  text-white 
  rounded-lg;
}

.dropdownContent:hover {
  @apply block;
}
.dropdownContentMobile:hover {
  @apply block;
}

.dropdown:hover .dropdownContent {
  @apply flex flex-col;
}
.dropdownMobile:hover .dropdownContentMobile {
  @apply flex flex-col items-center  justify-center;
}

.dropdownContentItem {
  @apply flex justify-between items-center
  rounded-lg
  w-full 
  py-4 px-2 text-left
  hover:bg-black bg-opacity-80 transition-all duration-200;
}
.dropdownContentItemMobile {
  @apply flex gap-2 items-center
  justify-center
  rounded-lg
  w-full
  py-2 px-2 text-left
  hover:text-blue-400;
}

.hideMenuNav {
  display: none;
}
.showMenuNav {
  display: block;
  position: fixed;
  width: 250px;
  height: 100%;
  top: 0;
  left: 0;
  bottom:0;
  background: rgba(0, 0, 0, 0.9);
  border-top-right-radius: 10%;
  border-bottom-right-radius: 10%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Disconnectsvg {
  
}
